@import "designSystem/colors.scss";

.input-component {
    display: flex;
    flex-direction: column;

    &.error {
        label {
            color: $error;
        }

        input {
            border-color: $error;
        }
    }

    label {
        margin-bottom: 5px;
        font-weight: 500;
        font-size: 15px;
        color: $font-primary;
    }

    input {
        width: 100%;
        height: 35px;
        box-sizing: border-box;
        border: 1px solid $border-color;
        border-radius: 5px;
        padding: 0 10px;

        &:focus {
            outline: 0;
            border-color: $primary;
        }

        &::placeholder {
            opacity: 0.7;
        }
    }

    .error-message {
        font-size: 13px;
        font-weight: 500;
        margin-top: 5px;
        color: $error;
    }
}
