@import "designSystem/colors.scss";

.skeleton-course-card {
    display: flex;
    background: $light-background;
    box-shadow: 0px 0px 10px 0px #efefef;
    border-radius: 8px;
    border-left: 5px solid $primary;
    padding: 20px 30px;
    box-sizing: border-box;
    margin-bottom: 30px;
    position: relative;

    .main-content {
        display: flex;
        flex-direction: column;
        flex: 1;

        .title {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 20px;

            .course-title {
                font-weight: 600;
                font-size: 18px;
                line-height: 22px;
                margin: 0;
                margin-right: 20px;
                color: $font-title;
            }
        }

        .description {
            margin-top: 15px;
            display: flex;
            flex-direction: column;
        }
    }

    .action {
        position: absolute;
        top: 20px;
        right: 15px;
        color: $font-primary;
        opacity: 0.5;
        
        .dots-icon {
            width: 25px;
            height: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}
