@import "designSystem/colors.scss";

.users-wrapper {
    padding: 40px;
    display: flex;
    flex-direction: column;
    height: 100%;
    box-sizing: border-box;

    .title-sortable:hover {
        cursor: pointer;
    }

    .pagination-bottom {
        margin-bottom: 50px;
        margin-top: 20px;
    }

    .users-button-holder {
        display: flex;
        align-items: center;
        gap: 10px;

        .search-bar {
            .search-bar-text {
                background: #FFFFFF;
                border: 0.2px solid #CBCBCB;
                box-sizing: border-box;
                border-radius: 32px;
                padding: 5px 15px;
            }
        }
    }

    .action {
        position: relative;
        color: $font-primary;

        .dots-icon {
            width: 25px;
            height: 25px;
            display: flex;
            justify-content: center;
            align-items: center;

            &:hover {
                cursor: pointer;
                opacity: 0.7;
            }
        }

        .options {
            position: absolute;
            background-color: $light-background;
            right: 5px;
            top: 30px;
            box-shadow: 0px 0px 10px 0px #efefef;
            width: 150px;
            z-index: 10;

            .option {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                padding: 10px;
                font-size: 13px;
                font-weight: 600;

                &.red {
                    color: $error;
                }

                &:hover {
                    cursor: pointer;
                    background-color: #fafafa;
                }

                svg {
                    margin-right: 10px;
                }
            }
        }
    }
}

.carnets-info {
    h4 {
        margin: 0;
    }
}

.carnets-button {
    grid-column: span 6;
    display: flex;
    justify-content: flex-end;
}

.carnets-table {
    border-collapse: collapse;

    thead {

        .tr:hover {
            background-color: $primary !important;
        }

        tr {
            th {
                font-size: 14px;
                line-height: 17px;
                color: #787878;
                font-weight: 500;
                text-align: left;
                padding: 15px 25px;

            }
        }
    }

    tbody {
        tr {
            background-color: $light-background;

            &:nth-child(even) {
                background-color: #fafafa;
            }

            td {
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
                color: #000000;
                padding: 15px 25px;
            }

            .search-icon:hover {
                cursor: pointer;
            }
        }
    }
}


.payments-table {

    border-collapse: collapse;

    thead {

        .tr:hover {
            background-color: $primary !important;
        }

        tr {
            th {
                font-size: 14px;
                line-height: 17px;
                color: #787878;
                font-weight: 500;
                text-align: left;
                padding: 15px 25px;

            }
        }
    }

    tbody {
        tr {
            background-color: $light-background;

            &:nth-child(even) {
                background-color: #fafafa;
            }

            td {
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
                color: #000000;
                padding: 15px 25px;
            }

            .search-icon:hover {
                cursor: pointer;
            }
        }
    }
}


.user-separator {
    margin-bottom: 50px;
}