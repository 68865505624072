@import "designSystem/colors.scss";

.payments-wrapper {
    padding: 40px;
    display: flex;
    flex-direction: column;
    height: 100%;
    box-sizing: border-box;

    .pagination-bottom {
        margin-bottom: 50px;
        margin-top: 20px;
    }
}

.status-cell {
    min-width: 100px;
}

.payment-info-modal__wrapper .content{
    min-height: 0;
}