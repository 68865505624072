@import "designSystem/colors.scss";

.auth-layout {
    display: flex;
    align-items: center;
    justify-content: center;

    .background-image {
        width: 100%;
        height: 100%;
        position: absolute;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .app-logo {
        position: absolute;
        max-width: 150px;
        top: 40px;
    }

    .login-component {
        display: flex;
        flex-direction: column;
        width: 100%;
    
        form {
            width: 100%;
    
            /*div.material-input-component:first-of-type {
                margin-bottom: 40px;
            }*/
            
            div.material-input-component {
                margin-bottom: 20px;
            }

            .forgot-password {
                display: block;
                text-decoration: none;
                color: $font-secondary;
                width: 100%;
                margin-bottom: 30px;
                margin-top: 10px;
                text-align: right;
                font-size: 12px;
                font-weight: 600;
            }
    
            button {
                margin-top: 40px;
            }
        }
    }

    .auth-layout__card {
        z-index: 5;
        background-color: $light-background;
        border-radius: 5px;
        padding: 35px 40px;
        box-sizing: border-box;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
        width: 470px;

        border-radius: 16px;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h1 {
            font-size: 20px;
            font-weight: 600;
            text-align: center;
            margin: 0;
            margin-bottom: 20px;
            color: $font-primary;
        }

        h2 {
            font-family: Montserrat;   
            font-style: normal;
            font-weight: 600;
            font-size: 17px;
            line-height: 159.9%;
            color: $font-title;
        }

        p {
            
            font-family: Montserrat;
            font-style: normal;
            font-weight: 500;
            font-size: 15px;
            line-height: 169.4%;
            color: $font-primary;
        }

        button {
            width: 100%;
            border-radius: 53px;
        }

        .separator {
            display: block;
            margin-bottom: 10px;
            text-align: center;
            font-size: 14px;
            line-height: 17px;
            color: $font-primary;
            font-weight: 500;
        }

        .error-message-global {
            color: $error;
            text-align: center;
            margin: 20px;
        }

        .success-message-global {
            text-align: center;
            margin: 20px;
        }
    }
}
