@import "designSystem/colors.scss";

.courses-wrapper {
    padding: 40px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

    .section-title {
        button {
            margin-left: 20px;
        }
    }

    .pagination-top {
        margin-bottom: 20px;
    }

    .pagination-bottom {
        margin-top: -10px;
        margin-bottom: 50px;
    }

}

.conditional-price{
  background: $light-background;
  padding:15px;
  margin:15px;
}

.conditional-holder{
  background: $background;
}

.manage-course-modal__wrapper {


    table {
        border-collapse: collapse;

        thead {

            .tr:hover {
                background-color: $primary!important;
            }

            tr {
                th {
                    font-size: 14px;
                    line-height: 17px;
                    color: #787878;
                    font-weight: 500;
                    text-align: left;
                    padding: 10px 5px;
                    /*padding: 15px 25px;*/
                }
            }
        }

        tbody {
            tr {
                background-color: $light-background;

                &:nth-child(even) {
                    background-color: #fafafa;
                }

                td {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 17px;
                    color: #000000;
                    padding: 10px 5px;
                    /*padding: 15px 25px;*/
                }

                .search-icon:hover {
                    cursor: pointer;
                }
            }
        }
    }

}
