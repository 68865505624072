.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;   
}
.text{

    display: block;
    text-align: center;

}