@import "designSystem/colors.scss";

.MuiFormLabel-root:not(.Mui-focused):not(.Mui-error) {
    opacity: 0.7 !important;
}

.MuiFormLabel-root.Mui-focused {
    color: $primary !important;
}

.MuiInput-underline:before {
    border-bottom-color: 1px solid rgba(0, 0, 0, 0.15);
}

.MuiInput-underline:after {
    border-bottom-color: $primary;
}

:hover .MuiInput-underline:after {
    border-bottom-color: $primary;
}

.MuiInput-underline:hover:not(.Mui-disabled):before {
    border-color: $primary;
}

.input-error-formik {
    color: red;

    .input-label {
        color: red !important;
    }

    .picture-wrapper {
        .picture {
            border: 1px dashed red !important;

            svg {
                color: red !important;
                opacity: 0.5;
            }
        }
    }
}

.input {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    .password-eye {
        position: absolute;
        right: 0;
        margin-top: 10px;
    }
}
