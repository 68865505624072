@import "designSystem/colors.scss";

.events-wrapper {
    padding: 40px;
    display: flex;
    flex-direction: column;
    height: 100%;
    box-sizing: border-box;

    iframe {
        width: 100%;
        height: 100%;
        border: 1px solid lightgray;
    }
}
