@import "designSystem/colors.scss";

.pagination-container {

    font-size: 14px;

    .pagination-label {
        margin-right: 10px;
        color: $font-primary;
    }

    .pagination-current {
        font-weight: bold;
        margin-right: 5px;
        color: $primary;

    }

    .pagination-option a{
        margin-right: 5px;
        font-weight: bold;

        text-decoration: none;
        color: $font-secondary;
    }

    .pagination-option a:hover {
        text-decoration: underline;
        color: $secondary;
    }
}