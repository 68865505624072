@import "designSystem/colors.scss";

.skeleton-chat-card {
    display: flex;
    justify-content: center;
    align-items: center;
    background: $light-background;
    border: 0.55px solid  #c4c4c486;
    border-radius: 8px;
    padding: 20px 20px;
    box-sizing: border-box;
    margin-bottom: 30px;
    position: relative;
    overflow: hidden;
    height: 120px;
    .photo{
        display: flex;
        margin-right: 20px;
        .chat-photo{
            width: 70px;
            height: 70px;
            border-radius: 35px;
            overflow: hidden;
        }
    }

    .main-content {
        display: flex;
        flex-direction: column;
        flex: 1;

        .title {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 20px;

            .course-title {
                font-weight: 600;
                font-size: 18px;
                line-height: 22px;
                margin: 0;
                margin-right: 20px;
                color: $font-title;
            }
        }

        .description {
            margin-top: 15px;
            display: flex;
            flex-direction: column;
        }
    }

    .action {
        position: absolute;
        top: 20px;
        right: 25px;
        color: $font-primary;
        opacity: 0.5;
        
        .dots-icon {
            width: 25px;
            height: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}
