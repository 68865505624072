@import "designSystem/colors.scss";

.notifications-wrapper {
    padding: 40px;
    display: flex;
    flex-direction: column;
    height: 100%;
    box-sizing: border-box;

    .pagination-bottom {
        margin-bottom: 50px;
        margin-top: 20px;
    }

    .section-title {
        button {
            margin-left: 20px;

            svg{
                margin-right: 10px;
            }
        }
    }
    .empty {
        padding: 20px;
    }
}
.dots-icon {
    width: 25px;
    height: 25px;
    position:relative;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
        cursor: pointer;
        opacity: 0.7;
    }
}
.options {
    position: absolute;
    background-color: $light-background;
    right: 5px;
    top: 30px;
    box-shadow: 0px 0px 10px 0px #efefef;
    width: 120px;
    z-index: 10;

    .option {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 10px;
        font-size: 13px;
        font-weight: 600;

        &.red {
            color: $error;
        }

        &:hover {
            cursor: pointer;
            background-color: #fafafa;
        }

        svg {
            margin-right: 10px;
        }
    }
}
