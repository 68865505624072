@import "designSystem/colors.scss";

.group-card {
    display: flex;
    background: $light-background;
    box-shadow: 0px 0px 10px 0px #efefef;
    border-radius: 8px;
    border-left: 5px solid $primary;
    padding: 20px 30px;
    box-sizing: border-box;
    margin-bottom: 30px;
    position: relative;

    .picture {
        width: 85px;
        height: 85px;
        margin-right: 20px;
        
        img {
            height: 100%;
            width: 100%;
            border-radius: 10px;
            object-fit: cover;
        }
    }

    .main-content {
        display: flex;
        flex-direction: column;
        flex: 1;

        .title {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 20px;

            .course-title {
                font-weight: 600;
                font-size: 18px;
                line-height: 22px;
                margin: 0;
                margin-right: 20px;
                color: $font-title;
            }

            .date {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                background-color: rgba(240, 232, 66, 0.63);
                color: #6d6b33;
                font-weight: 600;
                font-size: 12px;
                padding: 5px 10px;
                border-radius: 8px;

                svg {
                    margin-right: 10px;
                }
            }
        }

        .description {
            font-size: 14px;
            line-height: 25px;
            color: $font-secondary;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;  
            overflow: hidden;
        }
    }

    .secondary-content {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-end;
        flex: 1;
        padding-right: 20px;

        .places {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            background-color: rgba(162, 52, 54, 0.07);
            color: #a23436;
            font-weight: 600;
            font-size: 12px;
            padding: 5px 10px;
            border-radius: 8px;
            margin-bottom: 10px;
            svg {
                margin-right: 10px;
            }
        }

        .sessions {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            background-color: #f7f7f7;
            color: $font-primary;
            font-weight: 600;
            font-size: 12px;
            padding: 5px 10px;
            border-radius: 8px;

            svg {
                margin-right: 10px;
            }
        }
    }

    .action {
        position: absolute;
        top: 20px;
        right: 15px;
        color: $font-primary;

        .dots-icon {
            width: 25px;
            height: 25px;
            display: flex;
            justify-content: center;
            align-items: center;

            &:hover {
                cursor: pointer;
                opacity: 0.7;
            }
        }

        .options {
            position: absolute;
            background-color: $light-background;
            right: 5px;
            top: 30px;
            box-shadow: 0px 0px 10px 0px #efefef;
            width: 120px;
            z-index: 10;
            
            .option {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                padding: 10px;
                font-size: 13px;
                font-weight: 600;

                &.red {
                    color: $error;
                }

                &:hover {
                    cursor: pointer;
                    background-color: #fafafa;
                }

                svg {
                    margin-right: 10px;
                }
            }
        }
    }
}
