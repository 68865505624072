@import "designSystem/colors.scss";

.debate-groups-wrapper {
    padding: 40px;
    display: flex;
    flex-direction: column;
    height: 100%;
    box-sizing: border-box;
    
    .section-title {
        button {
            margin-left: 20px;
        }
    }
}
